import React from 'react'
import { DefaultLayout } from '../components/Layout'
import { Ellipsis } from '../components/Typography'

const NotFoundPage = () => (
  <DefaultLayout>
    <h1>
      404
      <Ellipsis />
    </h1>
    <h3>Page not found</h3>
  </DefaultLayout>
)

export default NotFoundPage
